@import "~/src/scss/index.scss";

.list-sectors {
  h1 {
    font-size: 26px;
    text-align: center;
  }

  .touchables {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;
    > div {
      width: 50%;
      padding: 5px;

      .ui.button {
        background-color: $primary;
        height: 120px;
      }
    }
  }

  .button { 
    .ui.button {
      background-color: $error;
      height: 65px;
    }
  }
}