@import "~/src/scss/index.scss";

.new-turns {
  h1 {
    font-size: 26px;
    text-align: center;
  }

  .ui.form .disabled.field {
    opacity: 1;

    .ui.disabled.input {
      opacity: 1;
    }
  }

  .ui.form input[type=text]{
    color: $background-dark;
    border: 1px solid $background-dark;
    text-align: center;
  }

  .touchables {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px 0;
    > div {
      width: 33.33%;
      padding: 5px;
  
      .ui.button {
        background-color: $primary;
        height: 65px;
      }
    }

    &__delete {
      background-color: $error !important;
    }
  }

  .button { 
    .ui.button {
      background-color: $primary;
      height: 65px;
    }
  }
}