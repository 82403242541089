@import "~/src/scss/index.scss";

.monitor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__waiting {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background-dark;

    > h1 { 
      color: $text-light;
      font-size: 56px;
    }
  }

  &__info{
    height: 55vh;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-video{
      width: 40%;
    }

    &-turns{
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > h1 {
        width: 100%;
        height: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary;
        color: $text-light;
        
      }
    }
  }

  &__call {
    height: 20vh;
    width: 100%;

    > h1 {
      height: 10vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      color: $text-light;
      font-size: 56px;
    }

    &-turns{
      width: 98%;
      margin-bottom: 20px;
    }
  }
}