@import "~/src/scss/index.scss";

.client-layout {
  min-height: 100vh;
  max-height: 100vh;

  &__footer {
    border-top: 3px solid $primary;

    > .ui.container {
      display: flex;

      .logo {
        width: 115px;
        margin: 5px 10px 0
      }

      &:last-of-type {
        justify-content: space-between;
        margin-top: 30px;
        font-size: 12px;
        opacity: 0.4;
      }
    }
  }
}