@import "~/src/scss/index.scss";

.top-bar {
  height: 80px;

  > .ui.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-top: 20px;
  }

  &__left {
    height: 100%;
    display: flex;
    align-items: center;

    .logo {
      height: 100%;
      > .ui.image {
        height: 100%;
      }
    }

    .menu{ 
      margin-left: 20px;
      
      a {
        margin: 0 5px;
        color: $text-light;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}