$primary: #045BA7;
$primary-hover: #0280b3;
$secondary: #FDDB00;

// Text
$text-light: #fff;

// Background
$background-dark: #383634;
$background-grey: #f0f3f4;
$background-dark-web: #383634;

// Border
$border-grey: #808080;

// Status
$success: #84b84c;
$warning: #f2711c;
$error: #9f3a38;