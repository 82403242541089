@import "~/src/scss/index.scss";

.turns-page {
  &__break {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $warning !important;
    width: 100px;
  }
  &__busy{
    position: absolute;
    right: 120px;
    top: 0;
    background-color: $error !important;
    width: 100px;
  }

  &__timer{
    position: absolute;
    right: 240px;
    top: 8px;
    justify-content: center;
    align-items: center;
  }
}
