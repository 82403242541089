@import "~/src/scss/index.scss";

.item-turn {
  .divider {
    margin-bottom: 20px;
  }

  .ticket {
    > h1 { 
      font-size: 70px;
      font-family:Arial, Helvetica, sans-serif;
      text-align: center;
    }
    > h2 { 
      font-size: 16px;
      font-family:Arial, Helvetica, sans-serif;
      text-align: center;
    }

    > h3 { 
      font-size: 14px;
      font-family:Arial, Helvetica, sans-serif;
      text-align: center;
    }

    > p { 
      font-size: 12px;
      font-family:Arial, Helvetica, sans-serif;
      text-align: center;
    }
  }

  .button {
    .ui.button {
      background-color: $primary;
      height: 65px;
      
    }
  }
}