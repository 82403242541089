@import "~/src/scss/index.scss";

.turns{
  width: 100%;
  margin-left: 20px;

  &__headers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    > div {
      margin-top: 4px;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      background-color: $secondary;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      
      > span {
        text-align: center;
        width: 50%;
      }
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    > div {
      margin-top: 4px;
      padding: 25px;
      width: 100%;
      text-align: center;
      font-size: 26px;
      background-color: $background-dark;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      
      > span {
        text-align: center;
        color: $text-light;
        width: 50%;
        font-size: 42px;
        font-weight: bold;
      }
    }
  }
}