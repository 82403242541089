@import "~/src/scss/index.scss";

.sectors-page {
  &__add {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $primary !important;

    > .ui.button {
      margin: 0;
    }
  }
}