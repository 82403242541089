@import "~/src/scss/index.scss";

.clients-page {
  &__buttons {
    display: flex;
    justify-content: right;
    position: absolute;
    right: 0;
    top: 0;

    &-add {
      margin-left: 10px !important;
      background-color: $primary !important;
  
      > .ui.button {
        margin: 0;
      }
    }
  }
  
}